<template>
    <LoginMB v-if="layout == 4"/>
    <LoginPC v-else/>
</template>

<script>
    import LoginMB from '@/pages/mb/auth/Login';
    import LoginPC from '@/pages/pc/auth/Login';

    export default {
        components:{
            LoginMB,
            LoginPC
        }
    }
</script>