<template>
    <div class="method-login d-flex align-items-center justify-content-center">
        <a class="button btn-social-login" style="background: #FFFFFF" @click.prevent="handleClickGetAuthCode" :disabled="!Vue3GoogleOauth.isInit">
            <i class="dsi dsi-color-googlecircle"></i></a>
        
        <a class="button btn-social-login dosiin_mr-2 dosiin_ml-2" style="background: #4D8CC7">
            <i class="dsi dsi-color-fbcircle"></i>
        </a>

        <a class="button btn-social-login"  style="background: #101010">
            <i class="dsi dsi-color-applewhite"></i>
        </a>
    </div>
</template>

<script>
    import { inject } from 'vue';
    export default {
        setup() {
            const Vue3GoogleOauth = inject("Vue3GoogleOauth");

            // google 
            async function GhandleClickGetAuthCode(){
                try {
                    const authCode = await this.$gAuth.getAuthCode();
                    console.log("authCode", authCode);
                } catch(error) {
                    //on fail do something
                    console.error(error);
                    return null;
                }
            }

            // facebook 
            
            return {
                GhandleClickGetAuthCode,
                Vue3GoogleOauth
            }
        },
    }
</script>