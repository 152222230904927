<template>
    <main class="dosiin_page-login">
        <BackHeader>
            <template v-slot:center>
                <img :src="_logo" alt="Dosi-in">
            </template>
        </BackHeader>
        <div class="social-group">
            <div class="box-wrapper">
                <LoginForm  />

                <!-- <div class="more-login">
                    <p class="line2">HOẶC ĐĂNG NHẬP BẰNG</p>
                </div>
                <SocialLogin /> -->

                <!-- <hr class="line"> -->

                <div class="register-link center">
                    Bạn chưa có tài khoản? 
                    <router-link class="primary-link_text" :to="{ name : 'register' }">Đăng ký</router-link>
                </div>
                <div class="condition">
                    Bằng cách tiếp tục bạn đã Đồng ý với 
                    <br>
                    <a href="/chinh-sach-bao-mat">Điều khoản dịch vụ, Chính sách bảo mật Dosiin</a>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import LoginForm from '@/components/forms/LoginForm';
    import SocialLogin from '@/components/buttons/SocialLogin';
    import BackHeader from '@/components/common/BackHeader';
    export default {
        components:{
            LoginForm,
            SocialLogin,
            BackHeader
        },
    }
</script>
